<template>
  <div class="pb-5 lines-bg index-bg">
    <page-sections :sections="page.fields.children" />
  </div>
</template>

<script>
import PageSections from '~/components/PageSections'
import homepageSchema from '~/assets/src/homepage-schema.json'

export default {
  components: {
    PageSections
  },
  async asyncData({ $api }) {
    return {
      page: await $api.getPage('home')
    }
  },
  head: function() {
    return { 
      title: `${this.page.fields.title}`,
      script: [{
        type: 'application/ld+json',
        json: homepageSchema
      }]
    }
  },
  mounted() {
    this.$store.commit('setLuckModal', true)
  }
}
</script>